import * as React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, Typography, Grid, Modal, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Dropzone } from '../Dropzone';
import { storage } from '../../App';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: theme.spacing.unit * 40,
  },
  borderContainer: {
    margin: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    borderColor: theme.palette.white,
    height: '145px',
    width: '145px',
    background: 'linear-gradient(180deg, #A3E7F0 0%, rgba(255, 255, 255, 0) 100%), #D6D6D6;',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  clickedBorderContainer: {
    background: '#A3E7F0',
  },
  border: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '125px',
    width: '125px',
    borderRadius: '50%',
    background: theme.palette.white,
  },
  boldText: {
    fontWeight: '600',
  },
  deleteModal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

function TemplateIcon(props) {
  return (
    <SvgIcon
      viewBox="0 0 85 73"
      fill="none"
      style={{
        fill: 'transparent',
        width: '65px',
        height: '73px',
      }}
      {...props}
    >
      <path d="M52.1696 54.3625H33.712V68.4342H52.1696V54.3625Z" stroke="#656565" strokeMiterlimit="10" />
      <path
        d="M48.6024 16.8405V7.6958H37.0893V16.8405H28.5439L42.8568 38.8873L56.7822 16.8405H48.6024Z"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path d="M84.3333 1H1V54.3626H84.3333V1Z" stroke="#656565" strokeMiterlimit="10" />
      <path d="M57.5131 68.4341H27.864V71.7236H57.5131V68.4341Z" stroke="#656565" strokeMiterlimit="10" />
      <path d="M84.3333 43.2734H1V54.3553H84.3333V43.2734Z" stroke="#656565" strokeMiterlimit="10" />
    </SvgIcon>
  );
}

function InstructionIcon(props) {
  return (
    <SvgIcon
      viewBox="0 0 95 78"
      fill="none"
      style={{
        fill: 'transparent',
        width: '95px',
        height: '78px',
      }}
      {...props}
    >
      <path
        d="M89.2004 43.1481V33.5571H83.1511V43.1481H78.6678L86.5442 51.0308L93.4917 43.1481H89.2004Z"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5248 43.1481V33.5571H6.48323V43.1481H2L9.87636 51.0308L16.8238 43.1481H12.5248Z"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path d="M74.2307 76.6479H21.0691V1H64.6347L74.2307 11.2498V76.6479Z" stroke="#656565" strokeMiterlimit="10" />
      <path d="M25.0994 26.188H68.665" stroke="#656565" strokeMiterlimit="10" />
      <path d="M25.0994 36.813H68.665" stroke="#656565" strokeMiterlimit="10" />
      <path d="M25.3834 47.3232H68.9491" stroke="#656565" strokeMiterlimit="10" />
      <path d="M25.4833 57.2129H69.0489" stroke="#656565" strokeMiterlimit="10" />
      <path d="M25.4833 68.4128H69.0489" stroke="#656565" strokeMiterlimit="10" />
    </SvgIcon>
  );
}
/* cvs wrong text it should be csv
<path   d="M40.9646 20.9149C40.5446 21.0829 39.8306 21.2229 39.1866 21.2229C37.2826 21.2229 36.1626 20.0329 36.1626 18.1429C36.1626 16.0429 37.4786 15.0209 39.1726 15.0209C39.9286 15.0209 40.5306 15.1889 40.9646 15.3709L41.3986 13.6769C41.0206 13.4809 40.1806 13.2569 39.0746 13.2569C36.2186 13.2569 33.9226 15.0489 33.9226 18.2689C33.9226 20.9569 35.6026 22.9869 38.8646 22.9869C40.0126 22.9869 40.8946 22.7769 41.2866 22.5809L40.9646 20.9149ZM47.5238 22.8469L50.7018 13.4109H48.4338L47.2298 17.4429C46.9078 18.5209 46.6138 19.6409 46.3758 20.7749H46.3338C46.1098 19.5989 45.8158 18.5209 45.4938 17.4009L44.3458 13.4109H42.0078L45.0318 22.8469H47.5238ZM51.2329 22.4129C51.7649 22.7069 52.8289 22.9869 53.9069 22.9869C56.4969 22.9869 57.7149 21.6429 57.7149 20.0609C57.7149 18.7309 56.9309 17.8629 55.2649 17.2469C54.0469 16.7849 53.5149 16.5189 53.5149 15.9169C53.5149 15.4269 53.9629 15.0069 54.8869 15.0069C55.8109 15.0069 56.4829 15.2729 56.8609 15.4549L57.3369 13.7329C56.7769 13.4809 55.9929 13.2569 54.9289 13.2569C52.7029 13.2569 51.3589 14.4889 51.3589 16.0989C51.3589 17.4709 52.3809 18.3389 53.9489 18.8849C55.0829 19.2909 55.5309 19.6269 55.5309 20.2149C55.5309 20.8309 55.0129 21.2369 54.0329 21.2369C53.1229 21.2369 52.2409 20.9429 51.6669 20.6489L51.2329 22.4129Z"
 fill="#656565"
 />*/
function UploadIcon(props) {
  return (
    <SvgIcon
      viewBox="0 0 86 73"
      fill="none"
      style={{
        fill: 'transparent',
        width: '86px',
        height: '73px',
      }}
      {...props}
    >
      <path d="M52.5789 54.7896H33.9736V68.9738H52.5789V54.7896Z" stroke="#656565" strokeMiterlimit="10" />
      <path d="M85 1H1V54.7895H85V1Z" stroke="#656565" strokeMiterlimit="10" />
      <path d="M57.9653 68.9736H28.079V72.2894H57.9653V68.9736Z" stroke="#656565" strokeMiterlimit="10" />
      <path
        d="M59.579 35.6757L57.3464 35.6462C53.8022 35.5946 50.8916 38.4241 50.8401 41.9683L50.8032 44.422L65.8716 44.3409L65.9011 42.1894C65.9527 38.6378 63.1232 35.7273 59.579 35.6757Z"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M57.9652 39.1904C60.8978 39.2346 63.3147 36.8914 63.3589 33.9514C63.4031 31.0188 61.0599 28.602 58.1199 28.5577C55.1873 28.5135 52.7705 30.8567 52.7263 33.7967C52.6894 36.7367 55.0326 39.1535 57.9652 39.1904Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M29.4274 35.5282L27.1948 35.4987C23.6506 35.4472 20.74 38.2766 20.6885 41.8208L20.6516 44.2745L35.72 44.1935L35.7495 42.0419C35.8011 38.4977 32.9716 35.5798 29.4274 35.5282Z"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M27.8137 39.0505C30.7463 39.0947 33.1632 36.7515 33.2074 33.8115C33.2516 30.8789 30.9085 28.4621 27.9685 28.4179C25.0358 28.3736 22.619 30.7168 22.5748 33.6568C22.5379 36.5968 24.8811 39.0063 27.8137 39.0505Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M55.5631 45.1588C55.6442 39.4704 51.1052 34.7841 45.4094 34.703L42.0863 34.6515C36.3979 34.5704 31.7115 39.1094 31.6305 44.8051L31.5715 48.7399L55.5042 48.6146L55.5557 45.1588H55.5631Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M42.8894 40.3178C47.4284 40.3841 51.1642 36.7589 51.2305 32.2126C51.2968 27.6736 47.6715 23.9378 43.1252 23.8715C38.5863 23.8052 34.8505 27.4304 34.7842 31.9768C34.7252 36.5157 38.3505 40.2515 42.8894 40.3178Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M54.9663 24.1737L55.0105 26.3031L57.7 26.2515L57.6558 24.1147L59.6526 24.0705L56.1968 18.9937L53.0579 24.2105L54.9663 24.1737Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M28.7199 23.982L28.7642 26.1188L31.4536 26.0599L31.4094 23.9231L33.3989 23.8862L29.9505 18.802L26.8115 24.0188L28.7199 23.982Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path
        d="M40.4735 17.6944L40.555 21.4905L45.4505 21.3897L45.3691 17.5937L49 17.5194L42.7205 8.49048L37 17.7633L40.4735 17.6944Z"
        fill="white"
        stroke="#656565"
        strokeMiterlimit="10"
      />
      <path d="M85 43.6189H1V54.7894H85V43.6189Z" fill="white" stroke="#656565" strokeMiterlimit="10" />
    </SvgIcon>
  );
}
function DeleteIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M20 6H16.5L15.71 4.54C15.62 4.34 15.5 4.18 15.35 4.03C15.19 3.87 15.03 3.75 14.83 3.66C14.63 3.56 14.38 3.5 14.13 3.5H9.87C9.62 3.5 9.37 3.56 9.17 3.66C8.97 3.75 8.81 3.87 8.65 4.03C8.5 4.18 8.38 4.34 8.29 4.54L7.5 6H4C3.45 6 3 6.45 3 7V8C3 8.55 3.45 9 4 9H20C20.55 9 21 8.55 21 8V7C21 6.45 20.55 6 20 6ZM7.5 7L8.29 5H15.71L16.5 7H7.5ZM5 20C5 20.55 5.45 21 6 21H18C18.55 21 19 20.55 19 20V10H5V20ZM13 12C13 11.45 13.45 11 14 11C14.55 11 15 11.45 15 12V18C15 18.55 14.55 19 14 19C13.45 19 13 18.55 13 18V12ZM9 12C9 11.45 9.45 11 10 11C10.55 11 11 11.45 11 12V18C11 18.55 10.55 19 10 19C9.45 19 9 18.55 9 18V12Z"
      />
    </SvgIcon>
  );
}
class RosterUploadInstructions extends React.PureComponent {
  static propTypes = {
    status: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired,
    onDropForDeleteRemaining: PropTypes.func,
    classes: PropTypes.any.isRequired,
    onUploadClick: PropTypes.func.isRequired,
    onUploadClickForDeleteRemaining: PropTypes.func,
    snapshotYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isEmptyRoster: PropTypes.bool,
    profile: PropTypes.object,
  };

  state = {
    instructionsClicked: false,
    templateClicked: false,
    showUploadConfirmationForDeleteRemaining: false,
  };

  onUploadClick = () => {
    const { onUploadClick } = this.props;
    this.dropZoneRef.open();
    onUploadClick();
  };

  onDrop = (accepted, rejected) => {
    const { onDrop, user } = this.props;
    onDrop(accepted, rejected, user);
  };
  onDropForDeleteRemaining = (accepted, rejected) => {
    const { onDropForDeleteRemaining, user } = this.props;
    onDropForDeleteRemaining(accepted, rejected, user);
  };
  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  saveDropZoneRefForDeleteRemaining = (dropZoneRefForDeleteRemaining) => {
    this.dropZoneRefForDeleteRemaining = dropZoneRefForDeleteRemaining;
  };

  onTemplateClick = async () => {
    const ref = storage.ref('public/StudentRosterTemplate.csv');
    const url = await ref.getDownloadURL();
    window.open(url);
    // window.open(
    //   'https://docs.google.com/spreadsheets/d/1x4s7DhtgHFKyM75iC1y5VTgNvbxaAsCRCCAYLirZB9I/export?format=csv',
    // );
    this.setState({ templateClicked: true });
  };

  onInstructionsClick = () => {
    window.open('https://docs.google.com/document/d/1jEi4a8ni4kOVJg5zM1q85xNEaorTdxQbyGZOhG3XHIs/export?format=pdf');
    this.setState({ instructionsClicked: true });
  };

  onUploadForDeleteRemaining = () => {
    this.setState({ showUploadConfirmationForDeleteRemaining: true });
  };

  okUploadConfirmationForDeleteRemaining = () => {
    this.setState({ showUploadConfirmationForDeleteRemaining: false });
    const { onUploadClickForDeleteRemaining } = this.props;
    this.dropZoneRefForDeleteRemaining.open();
    // onUploadClickForDeleteRemaining();
  };

  cancelUploadConfirmationForDeleteRemaining = () => {
    this.setState({ showUploadConfirmationForDeleteRemaining: false });
  };

  render() {
    const { status, classes, snapshotYear, isEmptyRoster, profile } = this.props;
    const { templateClicked, instructionsClicked, showUploadConfirmationForDeleteRemaining } = this.state;

    return (
      <div className={classes.root}>
        {
          <Typography gutterBottom variant="subtitle2" color="primary" align="center" className={classes.boldText}>
            {isEmptyRoster
              ? `You do not have a roster uploaded for snapshot year ${snapshotYear}-${snapshotYear + 1}`
              : `You are about to update the roster for snapshot year ${snapshotYear}-${snapshotYear + 1}`}
          </Typography>
        }
        <Typography gutterBottom variant="subtitle2" color="primary" align="center" className={classes.boldText}>
          There are three steps to the rostering process:
        </Typography>
        <Grid container justify="space-evenly" alignItems="center" direction="row">
          <Grid item>
            <Typography gutterBottom variant="body2" color="primary" align="center">
              1. Download the template.
            </Typography>
            <button
              type="button"
              className={classNames(classes.borderContainer, { [classes.clickedBorderContainer]: !!templateClicked })}
              onClick={this.onTemplateClick}
            >
              <div className={classes.border}>
                <TemplateIcon />
              </div>
            </button>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="body2" color="primary" align="center">
              2. Download instructions.
            </Typography>
            <button
              type="button"
              className={classNames(classes.borderContainer, {
                [classes.clickedBorderContainer]: !!instructionsClicked,
              })}
              onClick={this.onInstructionsClick}
            >
              <div className={classes.border}>
                <InstructionIcon />
              </div>
            </button>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="body2" color="primary" align="center">
              {status.uploaded ? '3. Update your uploaded roster.' : '3. Upload it back.'}
            </Typography>
            <button
              type="button"
              className={classNames(classes.borderContainer, { [classes.clickedBorderContainer]: !!status.uploaded })}
              onClick={this.onUploadClick}
            >
              <div className={classes.border}>
                <Dropzone
                  disableClick
                  accept=".csv"
                  ref={this.saveDropZoneRef}
                  onDrop={this.onDrop}
                  multiple={false}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <UploadIcon />
                </Dropzone>
              </div>
            </button>
          </Grid>
        </Grid>

        {profile && profile.isSuperAdmin == true && (
          <div>
            {showUploadConfirmationForDeleteRemaining && (
              <div>
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={showUploadConfirmationForDeleteRemaining}
                  onClose={this.cancelUploadConfirmationForDeleteRemaining}
                >
                  <div className={classes.deleteModal}>
                    <Typography variant="h6" id="modal-title" gutterBottom>
                      {`Are you sure you want to delete remaining students and keep only the students in the uploaded file?`}
                    </Typography>
                    <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: 24 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.cancelUploadConfirmationForDeleteRemaining}
                        style={{ width: 96, marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="inherit"
                        onClick={this.okUploadConfirmationForDeleteRemaining}
                        style={{ width: 96 }}
                      >
                        Yes
                      </Button>
                    </Grid>
                  </div>
                </Modal>
              </div>
            )}
            <Typography
              gutterBottom
              variant="body2"
              color="primary"
              align="center"
              style={{ color: 'red', fontWeight: 'bold' }}
            >
              Please take a backup first because after uploading the new roster, the system will delete the remaining
              students.
            </Typography>
            <Grid container justify="space-evenly" alignItems="center" direction="row">
              <Grid item>
                <Typography gutterBottom variant="body2" color="primary" align="center"></Typography>
                <div>
                  <button
                    type="button"
                    className={classNames(classes.borderContainer, {
                      [classes.clickedBorderContainer]: !!status.uploaded,
                    })}
                    onClick={this.onUploadForDeleteRemaining}
                  >
                    <div className={classes.border}>
                      <Dropzone
                        disableClick
                        accept=".csv"
                        ref={this.saveDropZoneRefForDeleteRemaining}
                        onDrop={this.onDropForDeleteRemaining}
                        multiple={false}
                        style={{
                          alignItems: 'left',
                          justifyContent: 'left',
                          display: 'flex',
                        }}
                      >
                        <DeleteIcon />
                      </Dropzone>
                    </div>
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}

        {status && status.error && (
          <Typography align="center" color="error">
            {status.error}
          </Typography>
        )}
        {status && status.message && (
          <Typography align="center" color="primary">
            {status.message}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(RosterUploadInstructions);
